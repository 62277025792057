// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sisa-tsx": () => import("./../../../src/pages/sisa.tsx" /* webpackChunkName: "component---src-pages-sisa-tsx" */)
}

